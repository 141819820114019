<template src="@/../../../templates/brand/components/templates/shop/Reservation.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapGetters } from 'vuex'
import accounting from 'accounting'

// logic
export default {
  name: 'Reservation',
  created: function () {
    this.$http.get('/orders?type=reservation&populate=true').then(res => {
      if (typeof res !== 'string') {
        this.reservations = res.data.orders
        this.ordercount = res.data.count
      }
    })
  },
  data () {
    return {
      locales: _.keys(this.$store.state.lang),
      reservations: [],
      ordercount: 0
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
  },
  methods: {
    orderStatus: function (order) {
      // if (!order.shipDate) return 'in progress';
      if (order.shipDate && !order.receivedDate) return 'en route'
      if (order.receivedDate && !order.paymentReceived)
        return 'Complete - payment outstanding'
      return order.status
    },
    currencyLocale: function (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Reservation.css"></style>
